import HomePage from 'gatsby-theme-husky/src/templates/HomePage/HomePage';
import { graphql } from 'gatsby';

import './homePageOverride.scss';

export const query = graphql`
  query($promoArticleTags: [Int], $promoArticlesBannerLimit: Int, $lang: String) {
    salsifyHomeProducts: allSalsifyProducts(filter: { langProps: { lang: { eq: $lang } } }) {
      nodes {
        productTitle
        productTitleWithSize
        url
        productSize
        productImage
        productEAN
        localProductImage {
          childImageSharp {
            fixed {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
        variantProductCards {
          title
          image
          size
          titleShort
          titleWithSize
          url
          ean
        }
      }
    }
    homepageSettings: allHomepageSettings(filter: { langProps: { lang: { eq: $lang } } }) {
      nodes {
        seoProps {
          seoMetaDescription
          seoMetaKeywords
          seoMetaTitle
          seoExternalHreflangs {
            key
            value
          }
        }
        langProps {
          urls {
            ...languageUrls
          }
          lang
        }
      }
    }
    homepageMainBanner: allHomepageMainBanner(filter: { lang: { eq: $lang } }) {
      nodes {
        content
        bannerVariant
        withWrapper
        button {
          ariaLabel
          text
          withReverse
          link {
            url
          }
        }
        imageAsBackground {
          fallbackUrl
          fluid {
            srcSet
            originalImg
            base64
          }
          variants {
            url
            type
            fallbackQuery
            alias
          }
        }
        imageAsBackgroundAlt
      }
    }
    believeBanner: allBelieveBanner(filter: { lang: { eq: $lang } }) {
      nodes {
        content
        bannerVariant
        imageAsBackgroundAlt
        imageAsBackgroundBelieve {
          fallbackUrl
          fluid {
            srcSet
            originalImg
            base64
          }
          variants {
            url
            type
            fallbackQuery
            alias
          }
        }
      }
    }
    homepageBlocks: allHomepageBlocks(filter: { lang: { eq: $lang } }) {
      nodes {
        productsCategoryList {
          allProductsText
          allProductsLink {
            url
          }
          isProductCategoryListVisible
          productsTitle
          productsDescription
          productContent {
            boxTitle
            productButton {
              ariaLabel
              link {
                url
              }
              text
              withReverse
            }
            productImage {
              fallbackUrl
              fluid {
                base64
                originalImg
                srcSet
              }
              variants {
                alias
                fallbackQuery
                type
                url
              }
            }
            productImageAlt
          }
        }
        recommendations {
          recommendationsTitle
          recommendationsList {
            recommendationsItemTitle
            recommendationsItemImage {
              fallbackUrl
              fluid {
                base64
                originalImg
                srcSet
              }
              variants {
                alias
                fallbackQuery
                type
                url
              }
            }
            recommendationsItemImageAltText
            recommendedProducts {
              name
              url
            }
          }
          learnMoreLabel
        }
        promoPanel {
          videoBanners {
            content
            button {
              ariaLabel
              text
            }
            bannerVariant
            imageAsBackgroundAlt
            withWrapper
            withVideo {
              link
              title
              autoplay
              image {
                fallbackUrl
                fluid {
                  base64
                  originalImg
                  srcSet
                }
                variants {
                  alias
                  fallbackQuery
                  type
                  url
                }
              }
            }
          }
          title
          viewAllButton {
            ariaLabel
            text
            link {
              url
            }
            withReverse
          }
          moreLabelText
          moreLabelTextAriaLabel
          promoPanelBackgroundImage {
            fallbackUrl
            fluid {
              srcSet
              originalImg
              base64
            }
            variants {
              url
              type
              fallbackQuery
              alias
            }
          }
          promoPanelBackgroundImageAlt
        }
        findYourVeetTool {
          findYourVeetIframe
          isFindYourVeetToolVisible
        }
        mainCarousel {
          carouselItemsDesktop {
            ...umbracoImageWithAlt
          }
          carouselItemsMobile {
            ...umbracoImageWithAlt
          }
        }
      }
    }
    promoBanners: allUmbracoArticles(
      filter: {
        tags: { elemMatch: { id: { in: $promoArticleTags } } }
        langProps: { lang: { eq: $lang } }
      }
      limit: $promoArticlesBannerLimit
    ) {
      nodes {
        title
        url
        articleThumbnailImage {
          fallbackUrl
          fluid {
            srcSet
            originalImg
            base64
          }
          variants {
            url
            type
            fallbackQuery
            alias
          }
        }
        articleImageAlt
      }
    }
  }
`;

export default HomePage;
